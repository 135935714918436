<template>
  <!-- 粪污施用记录 -->
  <div class="outer-page">
    <x-table
      :no-data-text="
        CA('wasteApplication_check') ? '暂无数据' : '暂无数据查询权限'
      "
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="500"
      @on-visible-change="modalShow"
    >
      <Form :label-width="120" ref="form" :model="form" :rules="rules">
        <!-- <FormItem label="施用设备" prop="deviceId">
          <Select style="width: 300px" v-model="form.deviceId">
            <Option
              :value="item.id"
              v-for="(item, index) in deviceList"
              :key="index"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem> -->
        <FormItem label="集散点" prop="collectionId">
          <Select style="width: 300px" v-model="form.collectionId">
            <Option
              :value="item.id"
              v-for="(item, index) in socialList"
              :key="index"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="用量" prop="amount">
          <Input
            style="width: 300px"
            placeholder="请填写粪污用量"
            v-model="form.amount"
          ></Input>
        </FormItem>
        <FormItem label="开始时间" prop="startTime">
          <DatePicker
            type="date"
            placeholder="请选择开始时间"
            style="width: 300px"
            v-model="form.startTime"
          ></DatePicker>
        </FormItem>
        <FormItem label="结束时间" prop="endTime">
          <DatePicker
            type="date"
            placeholder="请选择结束时间"
            style="width: 300px"
            v-model="form.endTime"
          ></DatePicker>
        </FormItem>
        <FormItem label="地址">
          <Input
            style="width: 300px"
            placeholder="请填写地址"
            v-model="form.address"
          ></Input>
        </FormItem>
        <FormItem label="内容">
          <Input
            style="width: 300px"
            type="textarea"
            placeholder="请填写内容"
            v-model="form.content"
          ></Input>
        </FormItem>
        <FormItem label="备注">
          <Input
            style="width: 300px"
            type="textarea"
            placeholder="请填写备注"
            v-model="form.remark"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          // {
          //   title: "施用设备",
          //   minWidth: 200,
          //   key: "deviceName",
          // },
          {
            title: "集散点",
            minWidth: 260,
            key: "collectionName",
          },
          {
            title: "用量",
            key: "amount",
          },
          {
            title: "开始时间",
            render: (h, { row }) => {
              return (
                <span>{row.startTime ? row.startTime.split(" ")[0] : ""}</span>
              );
            },
          },
          {
            title: "结束时间",
            render: (h, { row }) => {
              return (
                <span>{row.endTime ? row.endTime.split(" ")[0] : ""}</span>
              );
            },
          },
          {
            title: "地址",
            minWidth: 200,
            key: "address",
          },
          {
            title: "内容",
            key: "content",
          },
          {
            title: "备注",
            key: "remark",
          },
          //   {
          //     title: "操作",
          //     width: 200,
          //     render: (h, { row }) => {
          //       return (
          //         <div>
          //           {this.CA("wasteApplication_view") && (
          //             <a
          //               style="margin-right: 10px"
          //               onClick={() => this.checkInfo(row)}
          //             >
          //               查看详情
          //             </a>
          //           )}
          //         </div>
          //       );
          //     },
          //   },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增粪污施用记录",
            ca: "wasteApplication_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "集散点",
              component: "select",
              field: "collectionId",
              defaultValue: "",
              data: [],
              parameterField: "id",
              showField: "name",
              isCallback: true,
              mark: "1",
            },
            {
              conditionName:"地址",
              component:"input",
              field:"address",
              defaultValue:""
            }
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        // deviceId: "", //设备Id
        collectionId: "", //集散点Id
        startTime: "",
        endTime: "",
        amount: "", //用量
        address: "", //地点
        content: "", //内容
        remark: "",
      },
      rules: {
        // deviceId: [{ required: true, message: "请选择设备" }],
        collectionId: [{ required: true, message: "请选择集散点" }],
        startTime: [{ required: true, message: "请选择开始时间" }],
        endTime: [{ required: true, message: "请选择结束时间" }],
        amount: [{ required: true, message: "请填写施用用量" }],
      },
      search_data: {},
      //设备列表
      deviceList: [],
      //加工点
      socialList: [],
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.modal = {
        show: true,
        title: "新增粪污施用记录信息",
        submitLoading: false,
      };
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      if (!this.CA("wasteApplication_check")) return;
      this.table.loading = true;
      this.$post(this.$api.WASTE_APPLICATION.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    //施用设备
    // getDevice() {
    //   this.$post(this.$api.USE_DEVICE.LIST, {
    //     siteType: 3,
    //   }).then((res) => {
    //     this.deviceList = res.list;
    //     this.config.filter.filterBox[0].data = res.list;
    //   });
    //   //后端未返回设备名字只有id
    //   // this.$post(this.$api.SYS_CATEGORY.LIST, {
    //   //   pid: "235",
    //   // })
      
    // },

    //集散点
    getSocialList() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 999,
        siteType: "3",
      }).then((res) => {
        this.socialList = res.list;
        // this.deviceList = res.list;
        this.config.filter.filterBox[0].data = res.list;
      });
    },

    submit() {
      let params = this.form;
      let url;
      if (params.id) {
        url = this.$api.WASTE_APPLICATION.UPDATE;
      } else {
        url = this.$api.WASTE_APPLICATION.ADD;
      }
      params.startTime = moment(this.form.startTime).format("YYYY-MM-DD");
      params.endTime = moment(this.form.endTime).format("YYYY-MM-DD");
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
            this.getList();
            this.modal.show = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        // deviceId: "",
        collectionId: "",
        startTime: "",
        endTime: "",
        amount: "",
        address: "",
        content: "",
        remark: "",
      };
      this.$refs.form.resetFields();
    },
  
  },
  mounted() {
    this.getList();
    // this.getDevice();
    this.getSocialList();
    
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>